import Menu from "../components/Menu";

const Header = ({isMenuActive,setIsMenuActive}) =>{
    return(
        <div  className ="top-page-header">
            <Menu isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
            <div className ="top-page-header-img"></div>
            
        </div>
        
    );
};

export default Header;


