import { useState,useEffect} from "react";

const Menu = ({isMenuActive,setIsMenuActive}) =>{
    const [activeSection, setActiveSection] = useState('none');

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
        if (isMenuActive) {
          setActiveSection('none'); // メニューを閉じる時にセクションをクリア
        }
      };
    
    const handleSectionClick = (section) => {
    setActiveSection(section); // セクションを選択
    };
    
    const handleBackClick = () => {
    setActiveSection('none'); // 「戻る」ボタンをクリックしたらセクションをクリア
    };


    // スクロール時にメニューを閉じる関数
    const closeMenuOnScroll = () => {
        if (isMenuActive) {
        setIsMenuActive(false);
        setActiveSection('none');
        }
    };

    useEffect(() => {
        // スクロールイベントリスナーを追加
        window.addEventListener("scroll", closeMenuOnScroll);
    
        return () => {
          // コンポーネントがアンマウントされたらリスナーをクリーンアップ
          window.removeEventListener("scroll", closeMenuOnScroll);
        };
      }, [isMenuActive, setIsMenuActive]);


    return(
        <div className="top-page-menu">
            <div className={`openbtn5${isMenuActive ? ' active' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span className="menu-button">{activeSection ? 'Menu' : 'Back'}</span>
                <span></span>
            </div>
            <nav id="g-nav" className={isMenuActive ? 'panelactive' : ''}>
                {activeSection === 'none' && (
                    <ul>
                        <li><a href="/">にじさんじ</a></li>
                        <li><a href="/hololive">ホロライブ</a></li>
                        <li><a href="/vspo">ぶいすぽ!</a></li>
                        <li><a href="/policy">Privacy Policy</a></li>
                        <li><a href="#!" onClick={() => handleSectionClick('contact')}>Contact</a></li>
                        <li><a href="/about">About</a></li>
                        
                    </ul>
                )}
                {activeSection === 'contact' && 
                    <div>
                        <div className="menu-back" onClick={handleBackClick}>
                            <p>Back</p>
                        </div>
                        <div id="contact">
                            <h3>Contact</h3>
                            <p>MaiL: itchie_11@outlook.jp</p>
                            <p>掲載している動画においては作成者様の不利益とならないよう細心の注意を払っておりますが、掲載削除をご希望の場合にはメールにて承っております。</p>
                            <p>削除依頼に際してはチャンネルのご本人様確認のため、YoutubeのチャンネルページにてチャンネルIDが確認できるスクリーンショット取得し添付をお願い致します。</p>
                            
                        </div>
                    </div>
                }
            </nav>
            <div className={`circle-bg ${isMenuActive ? 'circleactive' : ''}`}></div>
        </div>
    );
};

export default Menu;


