import React from 'react';
import { useState,useEffect } from "react";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    // スクロールイベントを監視して、ボタンの表示を切り替える
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    // ページのトップに滑らかにスクロールする
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      
      // コンポーネントのアンマウント時にイベントリスナーをクリーンアップする
      return () => {
        window.removeEventListener('scroll', toggleVisibility);
      };
    }, []);
    // クラス名を動的に決定する関数
    const className = isVisible ? 'LeftMove' : 'RightMove';
  
    return (
        
      <div>
        <div id="page-top" className={className} onClick={scrollToTop}>
            <a href="#!" onClick={(e) => e.preventDefault()}>Top</a>
        </div>
      </div>
    );
  };

export default ScrollToTopButton;