const About = () =>{
    return(
        <div className ="top-page-container">
                <h1>About</h1>
                <div className ="about-container"> 
                    <p>推しが多くて配信を追いきれない。。<br/>切り抜きも見逃してしまう。。<br/>そんな自分と誰かのために、切り抜きを探すマシーンを作りました。</p>
                    <p>現在はシステムの仕組み上、にじさんじJPの切り抜きに絞っていたり、発見できずにいる切り抜きがあったりしますが、より多くの切り抜きを発見できるよう努めて参ります。</p>
                    <h2>YouTube APIサービスの利用について</h2>
                    
                    <div className ="about-sub-container">
                        
                        <p>当サイトでは掲載している動画情報の収集においてYouTubeAPIサービスを利用しており、YouTubeAPIサービス利用規約に同意しています。
                        YouTubeAPIサービスの利用規約については以下をご覧ください。</p>
                        <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service">https://developers.google.com/youtube/terms/api-services-terms-of-service</a>
                        
                        <div className ="about-sub-sub-container">
                            <p>YouTubeAPIサービスは、Google社のプライバシーポリシー、YouTubeの利用規約に基づいて提供されています。YouTubeの利用規約、Googleプライバシーポリシーについては以下をご覧ください。</p>
                            <p>YouTube利用規約</p>
                            <a href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>
                            <p>Googleプライバシーポリシー</p>
                            <a href="https://www.google.com/intl/ja/policies/privacy/">https://www.google.com/intl/ja/policies/privacy/</a>
                        </div>
                    </div>
            </div>
        </div>
        
    );
};

export default About;