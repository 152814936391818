import SelectorChId from "../components/SelectorChId";
import Lives from "../components/Lives" ;

const LivesPage = ({liversData,setLiver,kirinukiData,checked,setChecked,liveData,liverChId,setLiverChId,playlist}) =>{
    return(
        <div className ="top-page-container">
            <SelectorChId liversData={liversData} setLiverChId={setLiverChId} liverChId={liverChId}/>
            <Lives liveData={liveData} kirinukiData={kirinukiData} checked={checked} setChecked={setChecked} liverChId={liverChId} playlist={playlist}/>
        </div>
    );
};

export default LivesPage;

