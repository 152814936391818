import { useState } from "react";
import {Route,Routes,BrowserRouter,Navigate} from "react-router-dom"
import AboutPage from './pages/AboutPage';
import PolicyPage from './pages/PolicyPage';
import TopPage from './pages/TopPage';
import HLVPage from './pages/HLVPage';
import VSPPage from './pages/VSPPage';
import './App.css'; 

function App(){ 
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState(0);
  const [isMenuActive, setIsMenuActive] = useState(false);
  
  return( 
    
    <BrowserRouter>
      <Routes>        
        <Route path="/nijisanji" element={
          <TopPage checked={checked} setChecked={setChecked} value={value} setValue={setValue} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
        } />
        <Route path="/hololive" element={
          <HLVPage checked={checked} setChecked={setChecked} value={value} setValue={setValue} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
        } />
        <Route path="/vspo" element={
          <VSPPage checked={checked} setChecked={setChecked} value={value} setValue={setValue} isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
        } />
        <Route path="/about" element={
          <AboutPage isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
        } />
        <Route path="/policy" element={
          <PolicyPage isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
        } />
        <Route path="/" element={<Navigate to="/nijisanji"/>}/>
      </Routes>
    </BrowserRouter>
  ); 
} 
export default App;
