
// KirinukiPage. js
import Selector from "../components/Selector";
import Card from "../components/Cards" 

const KirinukiPage = ({liversData,setLiver,kirinukiData,liver}) =>{
    return(
        <div className ="top-page-container">
            <Selector liversData={liversData} setLiver={setLiver}/>
            <Card kirinukiData={kirinukiData} liver={liver}/>
        </div>
    );
};

export default KirinukiPage;

