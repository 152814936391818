
// LivesPage. js 
import Header from "../components/Header";
import KirinukiPage from './KirinukiPage';
import LivesPage from './LivesPage';
import ScrollToTopButton from "../components/ScrollToTopButton" ;
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState,useEffect } from "react";
import {useSearchParams} from "react-router-dom"


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }


const VSPPage = ({checked, setChecked,value,setValue,isMenuActive,setIsMenuActive}) =>{
    
    const [liversData,setLiversData] = useState([]);
    const [kirinukiData,setKirinukiData] = useState([]);
    const [liveData,setLiveData] = useState([]);
    const [liver,setLiver] = useState([]);
    const [liverChId,setLiverChId] = useState([]);
    const [loading, setLoading] = useState(true); // ローディング状態を管理
    const [searchParams] = useSearchParams();

    useEffect(() => {
      // クエリパラメータ 'id' の値を取得
      const selectLiver = searchParams.get('liver');
      // idValueが整数であるか確認
      if (selectLiver && !isNaN(selectLiver) && Number.isInteger(Number(selectLiver))) {
        setLiverChId([parseInt(selectLiver, 10)]);
      }
    }, [searchParams]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
      // 非同期関数を定義してAPIからデータを取得する
      async function fetchData() {
        try {
          const response = await fetch('https://enchanting-starlight-ef8751.netlify.app/liver_VSP.json');
          const jsonData = await response.json();
          // データをstateに格納し、ローディング状態を更新
          setLiversData(jsonData);
          setLoading(false);
        } catch (error) {
          console.error('データの取得に失敗しました:', error);
        }
      }
      // fetchData関数を呼び出す
      fetchData();
    }, []); // []はコンポーネントがマウントされた後に一度だけ実行されることを保証します
  
  
    // 切り抜きデータ取得
    useEffect(() => {
      // 非同期関数を定義してAPIからデータを取得する
      async function fetchData() {
        try {
          const response = await fetch('https://enchanting-starlight-ef8751.netlify.app/kirinuki_7daysAgo_VSP.json');
          const jsonData = await response.json();
          // データをstateに格納し、ローディング状態を更新
          setKirinukiData(jsonData);
          setLoading(false);
        } catch (error) {
          console.error('データの取得に失敗しました:', error);
        }
      }
      // fetchData関数を呼び出す
      fetchData();
    }, []); // []はコンポーネントがマウントされた後に一度だけ実行されることを保証します
  
  
    useEffect(() => {
      // 非同期関数を定義してAPIからデータを取得する
      async function fetchData() {
        try {
          const response = await fetch('https://enchanting-starlight-ef8751.netlify.app/original_video_7daysAgo_VSP.json');
          const jsonData = await response.json();
          // データをstateに格納し、ローディング状態を更新
          setLiveData(jsonData);
          setLoading(false);
        } catch (error) {
          console.error('データの取得に失敗しました:', error);
        }
      }
      // fetchData関数を呼び出す
      fetchData();
    }, []);

    return(
        <div className ="top-container">
            <Header isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
            <ScrollToTopButton />
            <h1 className ="top-h1">ぶいすぽっ！<br/>切り抜き探しマシーン</h1>
            <div className ="top-div">
              <p>推しが多くて配信を追いきれない<br/>切り抜きも見逃してしまう<br/>
              そんな自分と誰かのために<br/>切り抜きを探すマシーンを作りました<br/>
              良かったら見てってね</p>
           </div>
           <div className ="top-div">
           <h3>ちなみに</h3>
           <p>・見つけられていない切り抜きもあります<br/>
           ・何事もなければ朝と夕方に更新します<br/>
           ・動画は過去1週間分が見れます</p>
           </div>
            <Tabs value={value} onChange={handleChange} centered sx={{ background: '#0a0c44','& .MuiTabs-indicator': {
                backgroundColor: '#68cfff', // 下線の色
            },
              '& .MuiTab-root': {
                marginRight: '32px',
                marginLeft: '32px',
              }, }} >
                <Tab label="配信一覧" sx={{ fontSize: '24px', color:'#0070a4' }} />
                <Tab label="切り抜き一覧" sx={{ fontSize: '24px', color:'#0070a4' }} />
            </Tabs>
            <TabPanel value={value} index={0} >
                <div className ="top-page-container">
                    <LivesPage liveData={liveData} kirinukiData={kirinukiData} checked={checked} setChecked={setChecked} liversData={liversData} setLiver={setLiver} liver={liver}  liverChId={liverChId} setLiverChId={setLiverChId} loading={loading} playlist={"PLIpdCHWxATaaFFWYPX0FhYc1NAhpb1q6w"}/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className ="top-page-container">
                    <KirinukiPage liversData={liversData} setLiver={setLiver} kirinukiData={kirinukiData} liver={liver} loading={loading}/>
                </div>
            </TabPanel>
            
        </div>
    );
};

export default VSPPage;

