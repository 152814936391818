
import Header from "../components/Header" ;
import Policy from "../components/Policy" ;
import ScrollToTopButton from "../components/ScrollToTopButton" ;


const PolicyPage = ({isMenuActive,setIsMenuActive}) =>{
    return(
        <div className ="top-container">
            <Header isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
            <ScrollToTopButton />
            <Policy />
        </div>
    );
};

export default PolicyPage;

