
import Header from "../components/Header" ;
import About from "../components/About" ;
import ScrollToTopButton from "../components/ScrollToTopButton" ;


const AboutPage = ({isMenuActive,setIsMenuActive}) =>{
    return(
        <div className ="top-container">
            <Header isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive}/>
            <ScrollToTopButton />
            <About />
        </div>
    );
};

export default AboutPage;

