import React, { useState } from 'react';
import dayjs from 'dayjs';

// ライトボックス用のコンポーネント
const Lightbox = ({ isOpen, videoId, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="lightbox" onClick={onClose}>
      <div className="lightbox-content" onClick={e => e.stopPropagation()}>
        <div className="video-container">
            <iframe
            className="video-iframe"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            ></iframe>
        </div>
        <button onClick={onClose}>Close</button>
        </div>
    </div>
  );
};

const Card = ({ kirinukiData, liver, loading }) => {
    let now = dayjs();
    kirinukiData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState('');

    const openLightbox = (videoId) => {
        setCurrentVideoId(videoId);
        setLightboxOpen(true);
    };

    return (
        <div className="card-container">
            {kirinukiData.some(singleData => (
                    (liver.some(element => singleData.casts.includes(element)) || Object.keys(liver).length === 0) && singleData.casts.length < 20
                )) ? (
                    kirinukiData.map((singleData, index) => {
                        const publishedAtday = now.diff(dayjs(singleData.publishedAt).startOf('hour'), 'day')
                        return (
                        ((liver.some(element => singleData.casts.includes(element)) || Object.keys(liver).length === 0) && singleData.casts.length < 20) ? (
                            <div key={index} className="card" onClick={() => openLightbox(singleData.youtube_videoId)}>
                                <div className="contents">
                                    <div className="image-container">
                                        <img src={`https://i.ytimg.com/vi/${singleData.youtube_videoId}/mqdefault.jpg`} alt={singleData.title} />
                                        <div className="publishedAt">
                                            {publishedAtday ? publishedAtday + "日前" : now.diff(dayjs(singleData.publishedAt).startOf('hour'), 'hour') + "時間前"}
                                        </div>
                                    </div>
                                    <div className="creater">{singleData.createrName}</div>
                                    <h2 dangerouslySetInnerHTML={{ __html: singleData.title }}></h2>
                                </div>
                            </div>
                        ) : null
                    )}
                )
            ) : (
                <div className="no_videos">
                    {loading ? <h2>Loading...</h2> : <h2>No Videos</h2>}
                </div>
            )}
            <Lightbox isOpen={lightboxOpen} videoId={currentVideoId} onClose={() => setLightboxOpen(false)} />
        </div>
    );
};

export default Card;