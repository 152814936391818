// Lives.js
import dayjs from 'dayjs';
import Switch from 'react-switch';
import React, { useState } from 'react';

const Lightbox = ({ isOpen, videoId, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="lightbox" onClick={onClose}>
        <div className="lightbox-content" onClick={e => e.stopPropagation()}>
          <div className="video-container">
              <iframe
              className="video-iframe"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
              ></iframe>
          </div>
          <button onClick={onClose}>Close</button>
          </div>
      </div>
    );
  };

const Lives = ({liveData,kirinukiData,checked, setChecked, liverChId,loading,playlist}) => {
    let now = dayjs();
    liveData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentVideoId, setCurrentVideoId] = useState('');
    const [showModalSwitch, setShowModalSwitch] = useState(false);
    const [sortLiveKirinukiSwitch, setSortLiveKirinukiSwitch] = useState(false);

    const handleLiveKirinukiSortSwitchChange = (nextChecked) => {
        setSortLiveKirinukiSwitch(nextChecked);
    };

    const openLightbox = (videoId) => {
        setCurrentVideoId(videoId);
        setLightboxOpen(true);
    };

    const handleModalSwitchChange = (nextChecked) => {
        setShowModalSwitch(nextChecked);
    };


    const handleToggleChange = (nextChecked) => {
        setChecked(nextChecked);
      };

    function filterLiveByKirinuki(liveData, kirinukiData, checked, liverChId) {
    // 切り抜きが投稿された順にソート
    kirinukiData.sort((a, b) => new Date(a.publishedAt) - new Date(b.publishedAt));

    if (sortLiveKirinukiSwitch) {
        liveData = liveData.map(live => {
            const relatedKirinukis = kirinukiData.filter(k => k.video_Id === live.youtube_videoId);
            // relatedKirinukisが空の場合、非常に未来の日付をデフォルト値として設定
            const oldestKirinuki = relatedKirinukis.reduce((oldest, current) => new Date(oldest.publishedAt) > new Date(current.publishedAt) ? current : oldest, {publishedAt: '9999-12-31T23:59:59Z'});
            // 最も古いkirinukiのpublishedAtを使用してliveオブジェクトを更新
            return {...live, oldestKirinukiDate: oldestKirinuki.publishedAt};
        })
        // ここで、ピックアップされた最も古いkirinukiDataを新しい順にソート
        .sort((a, b) => new Date(b.oldestKirinukiDate) - new Date(a.oldestKirinukiDate));
    }


    if (checked) {
        if (Object.keys(liverChId).length === 0) {
            return liveData;
        } else {
            return liveData.filter((live) => {
                return liverChId.some(element => live.liver_id === element);
            });
        }
    } else {
        if (Object.keys(liverChId).length === 0) {
            return liveData.filter((live) => {
                return kirinukiData.some((kirinuki) => kirinuki.video_Id === live.youtube_videoId);
            });
        } else {
            return liveData.filter((live) => {
                return (liverChId.some(element => live.liver_id === element) && kirinukiData.some((kirinuki) => kirinuki.video_Id === live.youtube_videoId));
            });
        }
    }
}

    return(
        <div className="lives-container">
            <div className="toggle">
                <div className="toggle-nokirinuki">
                    <label>切り抜きがまだ無い配信も表示する</label>
                    <Switch onChange={handleToggleChange} checked={checked} />
                </div>
                <div className="toggle-modal">
                    <label>Youtubeにジャンプして動画を表示する</label>
                    <Switch onChange={handleModalSwitchChange} checked={showModalSwitch} />
                </div>
                <div className="toggle-sortkirinuki">
                    <label>最初に投稿された切り抜きが新しい順に表示する</label>
                    <Switch onChange={handleLiveKirinukiSortSwitchChange} checked={sortLiveKirinukiSwitch} />
                </div>
            </div>
            <div className="livelist">
                {filterLiveByKirinuki(liveData, kirinukiData, checked, liverChId).length === 0 ? (
                    <div className="no_videos">
                        {(loading)?(
                            <h2>Loading...</h2>
                        ):(
                            <h2>No Videos</h2>
                        )}
                    </div>
                ) : (
                    filterLiveByKirinuki(liveData, kirinukiData, checked,liverChId).map((singleData, index) => {
                        const publishedAtday = now.diff(dayjs(singleData.publishedAt).startOf('hour'), 'day')
                        return(
                        ((Object.keys(liveData).length !== 0)) ? (
                            
                        <div key={index} className="container">
                            {showModalSwitch ? (     
                                    <div className="head">
                                        <a href={`https://www.youtube.com/watch?v=${singleData.youtube_videoId}`} target="_blank" rel="noreferrer" className="live_img">
                                            <img src={`https://i.ytimg.com/vi/${singleData.youtube_videoId}/default.jpg`} alt={singleData.title} />
                                        </a>
                                    <div>
                                        <div  className="name_publishedAt">
                                            <div className="liver_name">
                                                {singleData.liver_name}
                                            </div>
                                            <div className="publishedAt">
                                                {publishedAtday ? publishedAtday+"日前":now.diff(dayjs(singleData.publishedAt).startOf('hour'), 'hour')+"時間前"}
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h2 dangerouslySetInnerHTML={{ __html: singleData.title}}></h2>
                                        </div>
                                    </div>
                                </div>
                                ) : (
                                    <div className="head">
                                        <img src={`https://i.ytimg.com/vi/${singleData.youtube_videoId}/default.jpg`} alt={singleData.title} onClick={() => openLightbox(singleData.youtube_videoId)}/>
                                        <div>
                                            <div  className="name_publishedAt">
                                                <div className="liver_name">
                                                    {singleData.liver_name}
                                                </div>
                                                <div className="publishedAt">
                                                    {publishedAtday ? publishedAtday+"日前":now.diff(dayjs(singleData.publishedAt).startOf('hour'), 'hour')+"時間前"}
                                                </div>
                                            </div>
                                            <div className="title">
                                                <h2 dangerouslySetInnerHTML={{ __html: singleData.title}}></h2>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="kirinukiList">
                            {kirinukiData.map((singleKirinuki, kirinukiindex) =>
                                ((singleKirinuki.video_Id === singleData.youtube_videoId)) ? (   
                                    <div>
                                        {showModalSwitch ? (
                                            <div key={kirinukiindex} className="kirinuki">
                                                <div>
                                                    <div className="image-container">
                                                        <a href={`https://www.youtube.com/watch?v=${singleKirinuki.youtube_videoId}`} target="_blank" rel="noreferrer">
                                                            <img src={`https://i.ytimg.com/vi/${singleKirinuki.youtube_videoId}/mqdefault.jpg`} alt={singleKirinuki.title} />
                                                        </a>
                                                    </div>
                                                    <div className="new">
                                                        {dayjs().diff(dayjs(singleKirinuki.publishedAt), 'day') < 1 && <span className="new-label">new</span>}
                                                    </div>
                                                    <div className="creater">
                                                        {singleKirinuki.createrName}
                                                    </div>
                                                    <div className="duration">
                                                        {singleKirinuki.duration}
                                                    </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div key={kirinukiindex} className="kirinuki"  onClick={() => openLightbox(singleKirinuki.youtube_videoId)}>
                                                    <div>
                                                        <div className="image-container">
                                                            <img src={`https://i.ytimg.com/vi/${singleKirinuki.youtube_videoId}/mqdefault.jpg`} alt={singleKirinuki.title} />
                                                        </div>
                                                        <div className="new">
                                                            {dayjs().diff(dayjs(singleKirinuki.publishedAt), 'day') < 1 && <span className="new-label">new</span>}
                                                        </div>
                                                        <div className="creater">
                                                            {singleKirinuki.createrName}
                                                        </div>
                                                        <div className="duration">
                                                            {singleKirinuki.duration}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    </div>
                                    
                                    ) : null
                            )}
                                
                            </div>
                        </div>
                        ) : null
                    );}
                    )
                )}
            </div>
            <Lightbox isOpen={lightboxOpen} videoId={currentVideoId} onClose={() => setLightboxOpen(false)} />
        </div>
    );
};
export default Lives;