// index. js 
import React from 'react'; 
import ReactDOM from 'react-dom/client';
import App from './App';
import Header from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <App/>
);