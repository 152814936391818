import Select  from 'react-select';
import makeAnimated from 'react-select/animated';
import React, { useState, useRef, useEffect } from 'react';

const SelectorChId = ({setLiverChId,liversData,liverChId}) =>{
    const animatedComponents = makeAnimated();
    const dropdownRef = useRef();
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
        //   borderBottom: '1px dotted pink',
          color: state.isSelected ? 'white' : 'blue',
          backgroundColor: state.isFocused ? '#cefdff' : null,
        //   padding: 20,
        }),
        multiValue: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? 'lightgrey' : '#cefdff', // フォーカス時の背景色と通常時の背景色
            borderRadius: '10px', // 角を丸くするための値
          }),
        multiValueLabel: (styles, { isFocused }) => ({
            ...styles,
            color: isFocused ? 'black' : 'blue', // フォーカス時のテキスト色と通常時のテキスト色
            borderRadius: '10px', // 角を丸くするための値
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            borderRadius: '10px', // 削除ボタンの右側の角を丸くする
            ':hover': {
              backgroundColor: '#4473ff',
              color: 'white',
            },
          }),
        control: (styles, { isFocused, isHovered }) => ({
            ...styles,
            backgroundColor: 'white', // セレクトボックスの背景色
            borderColor: isFocused ? 'none' : (isHovered ? 'none' : 'none'), // フォーカス時、ホバー時、通常時の枠線の色
            // フォーカス時の枠線の太さや色を強調するためにここで boxShadow と border を利用することもできます。
            boxShadow: isFocused ? 'none' : 'none',
            "&:hover": {
              borderColor: 'none' // マウスオーバー時の枠線の色
            }
          }),
        // 他のスタイルカスタマイズもここに追加できます...
      };

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const handleChange = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.id) : [];
        setSelectedOptions(selectedOptions);
        setLiverChId(values);
        setMenuIsOpen(false); // ドロップダウンを閉じる
    };

    

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
      };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMenuIsOpen(false); // ドロップダウンを閉じる
        }
    };

    useEffect(() => {
    // ドキュメント全体に対してクリックイベントリスナーを追加
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // コンポーネントのアンマウント時にイベントリスナーをクリーンアップ
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
      const selectedOptions = liversData.find(obj => {
        if (typeof liverChId[0] === 'number') {
          liverChId = liverChId[0];
        }
        if (obj.id === liverChId) {
          return true;
        }
        return false;
      });

      setSelectedOptions(selectedOptions);
    }, [liversData]);

    return(
        <div className ="selector-container">
            <div ref={dropdownRef}>
                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={liversData}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    styles={customStyles}
                    onChange={handleChange}
                    value={selectedOptions}
                    onMenuOpen={handleMenuOpen}
                    menuIsOpen={menuIsOpen}
                    placeholder="Select Livers..."
                />
            </div>
        </div>
    );
};

export default SelectorChId;