const Policy = () =>{
    return(
        <div className ="top-page-container">
            <div className ="policy-container">
                <h1>Privacy Policy</h1>
                <p>このプライバシーポリシーは、当サイトが提供するサービスに関する個人情報の収集、使用、および開示に関する方針を説明します。<br/>当サイトを利用することで、本ポリシーに同意するものとみなされます。</p>

                <div className ="policy-sub-container">
                    
                    <h2>個人情報の収集</h2>
                    <p>当サイトは、訪問者から個人情報を収集しません。<br/>
                    ただし、当サイトへのメールでのお問い合わせの際に、お名前、電子メールアドレス、その他の連絡先情報の記載は任意です。</p>

                    <h2>個人情報の使用</h2>
                    <p>メールにて記載いただいた個人情報は、お問い合わせに関する連絡にのみ使用します。<br/>それ以外の目的で個人情報を使用することはありません。</p>

                    <h2>個人情報の開示</h2>
                    <p>当サイトは、法的な要求に従う目的の場合には個人情報を開示する場合があります。<br/>ただし、それ以外の場合は個人情報を第三者に開示することはありません。</p>

                    <h2>Cookieの使用</h2>
                    <p>当サイトは、現在Cookieを使用しておりません。<br/>Cookieの利用を開始する際には、本サイトにてプライバシーポリシーの変更および変更を行った旨の記載をします。</p>

                    <h2>外部リンク</h2>
                    <p>当サイトは、外部ウェブサイトへのリンクを提供することがあります。<br/>これらの外部サイトには独自のプライバシーポリシーが適用されますので、当サイトからリンクをクリックした際には、外部サイトのプライバシーポリシーを確認してください。</p>

                    <h2>プライバシーポリシーの変更</h2>
                    <p>当サイトは、プライバシーポリシーを変更する権利を留保します。<br/>変更がある場合、当サイト上で通知されます。最新のプライバシーポリシーを確認してください。</p>

                    <h2>お問い合わせ</h2>
                    <p>プライバシーポリシーに関するご質問やお問い合わせがある場合は、以下の連絡先までお気軽にご連絡ください。</p>
                    <p>itchie_11@outlook.jp</p>

                </div>
            </div>
        </div>
        
    );
};

export default Policy;